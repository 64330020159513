<template>
  <div id="recently-added-videos">
    <router-link
      class="flex items-center justify-start p-4 pb-0 space-x-4 select-none"
      to="/recent-videos"
    >
      <img :src="ClockCountdownColorized" alt="RecentlyAddedIcon" />
      <p class="text-lg font-bold m-0 text-black dark:text-white">
        Recently Added Videos
      </p>
    </router-link>

    <div class="video-grid">
      <template v-if="loading">
        <VideoSkeleton v-for="n in numberOfVideos" :key="n" />
      </template>
      <template v-else>
        <VideoPreview
          v-for="video in videosToShow"
          :key="video.id"
          :video="video"
        />
      </template>
    </div>

    <div
      v-if="!loading && recentlyAddedVideos.length === 0"
      class="text-center"
    >
      No videos
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
} from "vue";
import VideoPreview from "@/components/videoPreview/VideoPreview.vue";
import VideoSkeleton from "@/components/VideoSkeleton.vue";
import { apiBackendAxios } from "@/axiosAuth.js";
import ClockCountdownColorized from "@/assets/playtv/icons/ClockCountdownColorized.svg";
import { transformToVideo } from "@/types/Video.tsx";
import { useSidebarStore } from "@/stores/sidebarStore";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  onDashboard: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = computed(() => props.loading);
const recentlyAddedVideos = ref([]);
const videosToShow = ref([]); // Only the videos to display
const numberOfVideos = ref(0);
const sidebarStore = useSidebarStore();
const gridWidth = ref(0);
const MAX_ROWS = 2; // Set the number of rows you want
let columns = 1;

const calculateColumns = () => {
  const adjustedWidth = Math.round(gridWidth.value / 10) * 10;
  columns = Math.floor(adjustedWidth / 290); // Adjust the division to use the rounded width
  if (columns < 1) columns = 1; // Ensure at least 1 column

  numberOfVideos.value = columns * MAX_ROWS;

  if (recentlyAddedVideos.value.length > 0) {
    updateVideosToShow();
  }
};

const updateVideosToShow = () => {
  const maxVideos = columns * MAX_ROWS;
  videosToShow.value = recentlyAddedVideos.value.slice(0, maxVideos);
};

// Observe changes in the grid width and recalculate columns
const observeGridWidth = () => {
  const videoGrid = document.querySelector(".video-grid");
  if (videoGrid) {
    const resizeObserver = new ResizeObserver(() => {
      gridWidth.value = videoGrid.clientWidth;
      if (gridWidth.value === 0) return;
      calculateColumns();
    });
    resizeObserver.observe(videoGrid);

    // Clean up observer
    onBeforeUnmount(() => {
      resizeObserver.disconnect();
    });
  }
};

// Load videos
const loadRecentlyAddedVideos = async () => {
  try {
    const {
      data: { data: videoDataIds },
    } = await apiBackendAxios.get("/v3/videos/all");

    if (videoDataIds.length === 0) {
      updateVideosToShow();
      emits("loadedVideos");
      return;
    }

    const {
      data: { data: videoData },
    } = await apiBackendAxios.post(`/v3/posts/map`, {
      data: videoDataIds.map((v) => v.ulid),
      responseType: "videos",
    });

    const { data } = await apiBackendAxios.post("/v3/profile", {
      ulids: videoData.map((v) => v.user.userId),
    });

    recentlyAddedVideos.value = videoDataIds
      .map((v) => v.ulid)
      .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
      .map((v1) => {
        return videoData.filter((v2) => v1 === v2.id)[0];
      })
      .map((v) => transformToVideo(v, data[0].data));

    if (props.onDashboard) {
      recentlyAddedVideos.value = recentlyAddedVideos.value.slice(0, 10);
    }

    updateVideosToShow();
    emits("loadedVideos");
  } catch (e) {
    console.error(e);
  }
};

// Watch for sidebar state changes and recalculate columns
watch(
  () => sidebarStore.expanded,
  () => {
    nextTick(() => {
      calculateColumns();
    });
  }
);

onMounted(() => {
  observeGridWidth();
  loadRecentlyAddedVideos();
});
</script>

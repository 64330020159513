<template>
  
  <!-- TODO, imitate blur  -->
  <!-- @blur="closeDropdown" -->

  <div class="dropdown" :class="props.mini ? 'mini' : ''" @click="toggleDropdown" @blur="onBlur" tabindex="0">
    <div class="dropdown-selected" v-if="props.mini === false">
      {{
      displayValueOnly
      ? selectedOption.value
      : selectedOption.label || placeholder
      }}
      <!-- {{ displayValueOnly ? selectedOption.value : selectedOption.label }} -->
      <span class="dropdown-arrow">&#9662;</span>
    </div>
    <div v-else>
      <svg data-v-0ec6fd49="" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path data-v-0ec6fd49=""
          d="M8.75 8.4541C8.75 8.60244 8.70601 8.74744 8.6236 8.87078C8.54119 8.99412 8.42406 9.09024 8.28701 9.14701C8.14997 9.20378 7.99917 9.21863 7.85368 9.18969C7.7082 9.16075 7.57456 9.08932 7.46967 8.98443C7.36478 8.87954 7.29335 8.7459 7.26441 8.60042C7.23547 8.45493 7.25032 8.30413 7.30709 8.16709C7.36386 8.03004 7.45999 7.91291 7.58332 7.8305C7.70666 7.74809 7.85166 7.7041 8 7.7041C8.19891 7.7041 8.38968 7.78312 8.53033 7.92377C8.67098 8.06442 8.75 8.25519 8.75 8.4541ZM8 4.9541C8.14834 4.9541 8.29334 4.91012 8.41668 4.8277C8.54001 4.74529 8.63614 4.62816 8.69291 4.49111C8.74968 4.35407 8.76453 4.20327 8.73559 4.05778C8.70665 3.9123 8.63522 3.77866 8.53033 3.67377C8.42544 3.56888 8.2918 3.49745 8.14632 3.46851C8.00083 3.43957 7.85003 3.45443 7.71299 3.51119C7.57594 3.56796 7.45881 3.66409 7.3764 3.78742C7.29399 3.91076 7.25 4.05577 7.25 4.2041C7.25 4.40301 7.32902 4.59378 7.46967 4.73443C7.61032 4.87508 7.80109 4.9541 8 4.9541ZM8 11.9541C7.85166 11.9541 7.70666 11.9981 7.58332 12.0805C7.45999 12.1629 7.36386 12.28 7.30709 12.4171C7.25032 12.5541 7.23547 12.7049 7.26441 12.8504C7.29335 12.9959 7.36478 13.1295 7.46967 13.2344C7.57456 13.3393 7.7082 13.4108 7.85368 13.4397C7.99917 13.4686 8.14997 13.4538 8.28701 13.397C8.42406 13.3402 8.54119 13.2441 8.6236 13.1208C8.70601 12.9974 8.75 12.8524 8.75 12.7041C8.75 12.5052 8.67098 12.3144 8.53033 12.1738C8.38968 12.0331 8.19891 11.9541 8 11.9541Z"
          fill="white"></path>
      </svg>
    </div>
    <ul v-if="isOpen" class="dropdown-list">
      <li v-for="option in options" :key="option.value" :class="{ selected: option.value === selectedOption.value }" @click="selectOption(option)">
        <a v-if="option.href" :href="option.href">
          {{ option.label }}
        </a>
        <span v-else @click.stop="selectOption(option)">
          {{ option.label }}
        </span>
      </li>
    </ul>
  </div>

  <!-- 
        Usage example:

      import CustomDropdown from "../customDropdown/CustomDropdown.vue";

      <CustomDropdown
        :options="dropdownOptions"
        placeholder="Actions"
        @change="handleAction"
      />

        // Define the dropdown options
        const dropdownOptions = [
          { label: 'Edit', value: 'edit' },
          { label: 'Delete', value: 'delete' },
          { label: 'Report', value: 'report' },
        ];

        // Method to handle the selected action
        const handleAction = (action) => {
          switch (action) {
            case 'edit':
              // Perform edit action
              console.log('Edit action selected');
              break;
            case 'delete':
              // Perform delete action
              console.log('Delete action selected');
              break;
            case 'report':
              // Perform report action
              console.log('Report action selected');
              break;
            default:
              console.log('No action selected');
          }
        };








        // Adding keyboard navigation
        
<template>
  <div
    class="dropdown"
    @click="toggleDropdown"
    @blur="closeDropdown"
    @keydown.enter.prevent="toggleDropdown"
    @keydown.down.prevent="focusNextOption"
    @keydown.up.prevent="focusPrevOption"
    tabindex="0"
    role="combobox"
    :aria-expanded="isOpen.toString()"
  >
  ...
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

// Existing code...

const focusedOptionIndex = ref(-1);

const focusNextOption = () => {
  if (!isOpen.value) {
    isOpen.value = true;
  }
  focusedOptionIndex.value =
    (focusedOptionIndex.value + 1) % options.length;
};

const focusPrevOption = () => {
  if (!isOpen.value) {
    isOpen.value = true;
  }
  focusedOptionIndex.value =
    (focusedOptionIndex.value - 1 + options.length) % options.length;
};

const selectFocusedOption = () => {
  const option = options[focusedOptionIndex.value];
  if (option) {
    selectOption(option);
  }
};

// Modify the list items to handle focus
</script>

Note: You'll need to adjust the template to reflect the focused option visually.

Using defineExpose
If you need to expose certain methods or variables to the parent component:

javascript
Copy code
defineExpose({
  isOpen,
  toggleDropdown,
  closeDropdown,
});











      -->
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { defineProps, defineEmits } from "vue";

onMounted(() => { });

// Define props
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean, Object, Array],
    default: null,
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  mini: {
    type: Boolean,
    required: false,
  },
  displayValueOnly: {
    type: Boolean,
    required: false,
  },
});

const { modelValue, options } = props;

function onChange(event) {
  emit("update:modelValue", event.target.value);
  emit("change", event.target.value);
}

const onBlur = (event) => {
  // console.log('onblur event', options, event);
  // console.log('onblur event', event?.relatedTarget);

  const relatedTarget = event.relatedTarget;

  if (relatedTarget && relatedTarget.tagName === 'A' && relatedTarget.hasAttribute('href')) {
    // console.log('Blurred to an <a> tag with href attribute');
    setTimeout(() => {
      closeDropdown();
    }, 3000);
  } else {
    // console.log('Blurred to an element without href attribute or not an <a> tag');
    closeDropdown();
  }
};

// Emit events
const emit = defineEmits(["update:modelValue", "change"]);

// Reactive variables
const isOpen = ref(false);
const selectedOption = ref({});

// Methods
const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const closeDropdown = () => {
  isOpen.value = false;
};

const selectOption = (option) => {
  selectedOption.value = option;
  isOpen.value = false;
  emit("update:modelValue", option.value);
  emit("change", option.value);
};

// Watchers
watch(
  () => modelValue,
  (newVal) => {
    selectedOption.value =
      options.find((option) => option.value === newVal) || {};
  },
  { immediate: true }
);
</script>

<style scoped>
/* Styles remain the same */
.dropdown {
  position: relative;
  overflow: visible;
  display: inline-block;
  width: 200px;
  user-select: none;
  outline: none;
  width: 100%;
  background-color: var(--background-secondary-color);
}

.dropdown.mini {
  width: auto;
  background-color: transparent;
  cursor: pointer;
  padding: 7px 2px;
}

.dropdown-selected {
  padding: 10px;
  border: 1px solid var(--input-border-color);
  cursor: pointer;
  /* background-color: #fff; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.dropdown-arrow {
  margin-left: auto;
}
.dropdown-list {
  position: absolute;
  right: 0;
  width: 100%;
  max-height: 200px;
  min-width: 180px;
  overflow-y: auto;
  border: 1px solid var(--input-border-color);
  /* border-top: none; */
  background-color: var(--background-secondary-color);
  z-index: 2000;
}
.dropdown-list li {
  line-height: 38px;
  text-indent: 10px;
  cursor: pointer;
}
.dropdown-list a {
  color: inherit;
  display: inline-block;
  width: 100%;
  text-indent: initial;
}
.dropdown-list li:hover,
.dropdown-list li.selected {
  background-color: var(--background-secondary-color-active);
}
</style>

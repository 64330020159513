import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth.js";

// @ts-ignore
export const useGlobalStore = defineStore("globalStore", {
  state: () => {
    return {
      isUpdatingAccessToken: false,
      reactionTypes: [],
    };
  },
  actions: {
    setUpdatingAccessToken(value) {
      this.isUpdatingAccessToken = value;
    },
    async setReactionTypes() {
      console.log("Setting reaction types");
      try {
        const response = await apiBackendAuthAxios.get("/settings/reactions");
        this.reactionTypes = response.data.data;
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    },
  },
});
